<template>
    <marketing-layout>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <form-field-search-support
                        class="my-5"
                        input-placeholder="Search question, keywords, topics"
                        :input-max-entries="3"
                        :exclude-snippets="true"
                        :inline-article="false"
                        :show-feedback-button="true"
                        @article-clicked="articleClicked"
                    />
                    <div
                        v-if="!loading"
                        class="mt-5 mt-md-8"
                    >
                        <div
                            v-if="article"
                            class="mb-5"
                        >
                            <h5 class="text-responsive">
                                {{ article.title }}
                            </h5>
                            <div
                                ref="articleBody"
                                v-html="article.body"
                            />
                        </div>
                        <div
                            v-else
                            class="d-flex justify-content-center align-content-center"
                        >
                            <div class="spinner-border spinner-border-sm" />
                        </div>
                    </div>
                    <div
                        v-else
                        class="d-flex justify-content-center align-content-center mt-5 mt-md-8"
                    >
                        <div class="spinner-border spinner-border-sm" />
                    </div>
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import MarketingLayout from '@/layouts/Marketing'
    import FormFieldSearchSupport from '@/components/base/FormFieldSearchSupport'
    import { supportPageNames, supportPagePaths } from '@/routes/supportRoutes'
    import { logger } from '@/utils/logger'

    export default {
        name: 'SupportArticle',
        metaInfo: {
            title: 'Support Article',
            meta: [
                {
                    name: 'Aven Support',
                    vmid: 'avensupport',
                    content: 'Support',
                },
            ],
        },
        components: {
            MarketingLayout,
            FormFieldSearchSupport,
        },
        data() {
            return {
                loading: true,
                id: this.$route.params?.id,
                article: null,
            }
        },
        mounted: function () {
            if (this.id) {
                // Look up and display article
                this.getArticle()
            } else {
                this.$router.push({ path: supportPagePaths.SUPPORT_HOME })
            }
        },
        updated: function () {
            // Hack to maintain YT videos aspect ratio. The width/height of the video's iframe is hard coded.
            // We need to set the width to 100% and use a padding-bottom trick to make sure the video's height
            // adjusts accordingly as the width of the video grows.
            this.$nextTick(function () {
                if (this.$refs.articleBody) {
                    const youtubeIframes = this.$refs.articleBody.querySelectorAll(`iframe[src*='youtube']`)

                    for (const youtubeIframe of youtubeIframes) {
                        const { width, height } = youtubeIframe.getBoundingClientRect()

                        const aspectRatio = height / width

                        youtubeIframe.style.width = '100%'
                        youtubeIframe.style.height = '100%'
                        youtubeIframe.style.position = 'absolute'
                        youtubeIframe.parentNode.style.paddingBottom = `${aspectRatio * 100}%`
                        youtubeIframe.parentNode.style.position = 'relative'
                    }
                }
            })
        },
        methods: {
            async getArticle() {
                try {
                    const result = await fetch(`https://support.aven.com/api/v2/help_center/en-us/articles/${this.id}`)
                    if (result.status === 200) {
                        const json = await result.json()
                        this.article = json.article
                        this.loading = false
                        this.$logEvent('view_support_article', { articleID: this.id, articleTitle: this.article.title })
                    } else {
                        await this.$router.push({ path: supportPagePaths.SUPPORT_HOME })
                    }
                } catch (error) {
                    logger.error(`Failed to get support article`, error)
                    await this.$router.push({ path: supportPagePaths.SUPPORT_HOME })
                }
            },
            articleClicked(id) {
                this.$router.push({ name: supportPageNames.SUPPORT_ARTICLE, params: { id } })
            },
        },
    }
</script>
