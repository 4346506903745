<template>
    <marketing-layout>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <h2 class="text-responsive mt-5 mt-md-8 text-center">
                        Hi! How can <br>
                        we help?
                    </h2>
                    <form-container
                        id="feedbackBoxWithSearch"
                        ref="feedbackBoxWithSearch"
                        class="col-12 col-md-8 offset-md-2"
                    >
                        <form-field-search-support
                            input-placeholder="Search question, keywords, topics"
                            :input-max-entries="3"
                            :exclude-snippets="true"
                            :inline-article="false"
                            :show-feedback-button="true"
                            @article-clicked="articleClicked"
                        />
                    </form-container>
                    <div
                        ref="articles"
                        v-if="!loading"
                        class="mt-5 mt-md-8"
                    >
                        <!-- IF LABEL IS IN QUERY PARAM -->
                        <div
                            v-if="label"
                            class="row gx-0 support-list-section"
                        >
                            <div class="col-12 col-md-5">
                                <h5 class="mb-3 text-capitalize">
                                    {{ label }}
                                </h5>
                            </div>
                            <div class="col-12 col-md-7">
                                <ul>
                                    <support-expanding-article
                                        v-for="(article, index) in articlesJson"
                                        :key="index"
                                        :article="article"
                                        :visible="true"
                                    />
                                </ul>
                            </div>
                        </div>

                        <!-- NO LABEL / SHOW ALL ARITCLES -->
                        <div
                            v-if="!label"
                            class="row gx-0 support-list-section"
                        >
                            <div class="col-12 col-md-5">
                                <h5 class="mb-3">
                                    Trending Articles
                                </h5>
                            </div>
                            <div class="col-12 col-md-7">
                                <ul>
                                    <support-expanding-article
                                        v-for="(article, index) in articlesJson.filter((article) => article.promoted)"
                                        :key="index"
                                        :article="article"
                                        :visible="true"
                                    />
                                </ul>
                            </div>
                        </div>
                        <div
                            class="row gx-0 support-list-section"
                            v-for="(section, index) in sectionsJson"
                            :key="index"
                        >
                            <div class="col-12 col-md-5">
                                <h5 class="mb-3">
                                    {{ section.name }}
                                </h5>
                            </div>
                            <div class="col-12 col-md-7">
                                <ul>
                                    <support-expanding-article
                                        v-for="(article, index) in articlesJson.filter((article) => article.section_id === section.id)"
                                        :key="index"
                                        :article="article"
                                        :visible="index < 3 || expandSection.indexOf(section.id) !== -1"
                                    />
                                </ul>
                                <a
                                    v-show="articlesJson.filter((article) => article.section_id === section.id).length > 3"
                                    @keydown.enter="setExpandSection(section.id)"
                                    @click="setExpandSection(section.id)"
                                    class="show-more"
                                >{{ expandSection.indexOf(section.id) !== -1 ? 'Show Less' : 'Show More' }}</a>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="d-flex justify-content-center align-content-center mt-5 mt-md-8"
                    >
                        <div class="spinner-border spinner-border-sm" />
                    </div>
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import MarketingLayout from '@/layouts/Marketing'
    import FormContainer from '@/components/base/FormContainer'
    import FormFieldSearchSupport from '@/components/base/FormFieldSearchSupport'
    import { supportPageNames } from '@/routes/supportRoutes'
    import SupportExpandingArticle from '@/components/SupportExpandingArticle'
    import { logger } from '@/utils/logger'

    export default {
        name: 'Support',
        metaInfo: {
            title: 'Support',
            meta: [
                {
                    name: 'Aven Support',
                    vmid: 'avensupport',
                    content: 'Search question, keywords, topics',
                },
            ],
        },
        components: {
            MarketingLayout,
            FormContainer,
            FormFieldSearchSupport,
            SupportExpandingArticle,
        },
        data() {
            return {
                loading: true,
                sectionsJson: null,
                articlesJson: null,
                label: this.$route.query.label,
                expandSection: [],
                articlesShowing: [],
            }
        },
        mounted: async function () {
            this.$logEvent('view_support', { label: this.label })
            if (this.label) {
                await this.getAllArticlesByLabel()
            } else {
                await this.getAllSections()
                await this.getAllArticles()
            }
            this.loading = false
        },
        updated: function () {
            // Hack to maintain YT videos aspect ratio. The width/height of the video's iframe is hard coded.
            // We need to set the width to 100% and use a padding-bottom trick to make sure the video's height
            // adjusts accordingly as the width of the video grows.
            this.$nextTick(function () {
                if (this.$refs.articles) {
                    const youtubeIframes = this.$refs.articles.querySelectorAll(`iframe[src*='youtube']`)

                    for (const youtubeIframe of youtubeIframes) {
                        const aspectRatio = 9 / 16

                        youtubeIframe.style.width = '100%'
                        youtubeIframe.style.height = '100%'
                        youtubeIframe.style.position = 'absolute'
                        youtubeIframe.parentNode.style.paddingBottom = `${aspectRatio * 100}%`
                        youtubeIframe.parentNode.style.position = 'relative'
                    }
                }
            })
        },
        methods: {
            articleClicked(id) {
                this.$router.push({ name: supportPageNames.SUPPORT_ARTICLE, params: { id } })
            },
            setExpandSection(id) {
                if (this.expandSection.indexOf(id) === -1) {
                    this.expandSection.push(id)
                } else {
                    this.expandSection.splice(this.expandSection.indexOf(id), 1)
                }
            },
            toggleArticle(id) {
                if (this.articlesShowing.indexOf(id) === -1) {
                    this.articlesShowing.push(id)
                } else {
                    this.articlesShowing.splice(this.articlesShowing.indexOf(id), 1)
                }
            },
            async getAllSections() {
                try {
                    const result = await fetch(`https://support.aven.com/api/v2/help_center/en-us/sections`)
                    const json = await result.json()
                    this.sectionsJson = json.sections
                } catch (error) {
                    logger.error(`Failed to get sections`, error)
                }
            },
            async getAllArticles() {
                try {
                    const result = await fetch(`https://support.aven.com/api/v2/help_center/en-us/articles.json?page[size]=100`)
                    const json = await result.json()
                    this.articlesJson = json.articles
                    this.promotedArticlesJson = this.articlesJson.filter((article) => article.promoted)
                } catch (error) {
                    logger.error(`Failed to get articles`, error)
                }
            },
            async getAllArticlesByLabel() {
                try {
                    const result = await fetch(`https://support.aven.com/api/v2/help_center/articles/search.json?label_names=${encodeURIComponent(this.label)}`)
                    const json = await result.json()

                    if (json.results.length > 0) {
                        this.articlesJson = json.results
                    } else {
                        this.label = null
                        await this.getAllSections()
                        await this.getAllArticles()
                    }
                } catch (error) {
                    logger.error(`Failed to get articles by label`, error)
                }
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/pages/support/supportLanding';
</style>
