<template>
    <marketing-layout>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <div
                        class="calendly-inline-widget"
                        :data-url="brandedCalendarUrl()"
                    />
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import MarketingLayout from '@/layouts/Marketing'

    const CALENDLY_SCRIPT_URL = 'https://assets.calendly.com/assets/external/widget.js'

    // TODO: Remove this link altogether.  Some people have access to this link already
    //   and we want them to be able to use is for a few weeks.  After that, we can
    //   display a "this link is expired" message.
    const DEFAULT_CALENDAR_URL = 'https://calendly.com/avencard/support'

    const injectCalendlyScript = () => {
        const calendlyScript = document.createElement('script')
        calendlyScript.setAttribute('src', CALENDLY_SCRIPT_URL)
        document.head.appendChild(calendlyScript)
    }

    export default {
        name: 'Support',
        metaInfo: {
            title: 'Support',
            meta: [
                {
                    name: 'Aven Call Booking',
                    vmid: 'avensupport',
                    content: 'Book a call with Aven',
                },
            ],
        },
        components: {
            MarketingLayout,
        },
        mounted: async function () {
            this.$logEvent('view_support_call_booking')
            injectCalendlyScript()
        },
        methods: {
            calendarUrl: function () {
                if (this.$route.params.bookingUrl) {
                    return atob(this.$route.params.bookingUrl)
                }

                return DEFAULT_CALENDAR_URL
            },
            brandedCalendarUrl: function () {
                return `${this.calendarUrl()}?primary_color=1a1a1a`
            },
        },
    }
</script>
<style lang="scss">
    .calendly-inline-widget {
        min-width: 320px;
        height: 900px;
    }
</style>
