<template>
    <marketing-layout>
        <div class="container px-0">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <section-block class="text-center">
                        <div class="section-header text-muted">
                            {{ $t('pages.survey.thankYou.title') }}
                        </div>
                        <h3 class="my-3">
                            {{ $t('pages.survey.thankYou.subTitle') }}
                        </h3>
                        <h5
                            class="text-muted"
                            v-html="$t('pages.survey.thankYou.message')"
                        />
                        <div class="d-grid">
                            <a
                                class="btn btn-primary mt-3"
                                href="sms:3233296731"
                            >
                                {{ $t('pages.survey.thankYou.sendTextMsg') }}
                            </a>
                        </div>
                    </section-block>
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'ThankYou',
        components: {
            'marketing-layout': Marketing,
            'section-block': SectionBlock,
        },
        mounted: async function () {
            this.$logEvent('view_email_survey_submitted')
        },
    }
</script>

<style scoped></style>
