<template>
    <li v-show="visible">
        <a
            @click="expand"
            @keydown.enter="expanded = !expanded"
            class="d-flex justify-content-between mb-2 title"
            :class="{ 'fw-bold': expanded }"
        >
            {{ article.title }}
            <img
                :class="{ flipped: expanded }"
                src="@/assets/images/pages/support/down.svg"
                alt="down"
            >
        </a>
        <span
            v-show="expanded"
            v-html="article.body"
        />
    </li>
</template>

<script>
    export default {
        name: 'SupportExpandingArticle',
        props: {
            article: Object,
            visible: Boolean,
        },
        data() {
            return {
                expanded: false,
            }
        },
        methods: {
            expand() {
                if (!this.expanded) {
                    // Log only if not currently expanded, and they are expanding it
                    this.$logEvent('view_support_expanded_article', { articleId: this.article.id, articleTitle: this.article.title })
                }
                this.expanded = !this.expanded
            },
        },
    }
</script>
